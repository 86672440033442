.container.contact-container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap:12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2em;
}

.contact__option{
    background: var(--color-bg-variant);
    padding: 0.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contanct__option:hover{
    background: var(--color-bg-variant);
    border-color: var(--color-primary-variant);
}

.contact__option-icon{
    margin-top: 0.4rem;
    font-size: 1.5rem;
    margin-bottom: 0.4rem;  
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ==================== FORM ==================== */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2em;
}
input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 2px solid var(--color-bg-variant);
    background: transparent;
    resize: none;
    color: var(--color-text);
}

/* ==================== Media Querius (Medium devices) =====================*/

@media screen and (max-width: 1024px) {
    .container.contact-container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }    
}

/* ==================== Media Querius (Smaller devices) =====================*/

@media screen and (max-width: 600px) {
    .container.contact-container {
        width: var(--container-width-sm);
    }
}