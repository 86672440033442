.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.experience__container > div {
    background: var(--color-bg-variant);
    padding: 1.4rem 1rem;
    padding-left: 10%;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    cursor: default;

}
.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}
.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.experience__details {
    display: flex;
    gap: 1rem;
}
.experience__details-icon{
    margin-top: 6px;
    color : var(--color-primary);
    font-size: var(--nav-icon-size-sm);

}


/* ==================== Media Querius (Large devices) =====================*/


@media screen and (min-width: 1440px) {
    .experience__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 2rem;
    }

    .experience__container > div {
        padding-left: 2rem;

    }
    
}


/* ==================== Media Querius (Medium devices) =====================*/

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;    
    }
    .experience__container > div {
        width: 97%;
        padding: 1rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 0.7rem
    }
}

/* ==================== Media Querius (Smaller devices) =====================*/

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }
    .experience__container > div {
        width: 98%;
    }
    .experience__content {
        padding: 0.2rem
    }
}
