nav{
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 1.7rem, 2.7rem;
    z-index: 3;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.5rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 1.5rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-ligth);
    font-size: (var(--nav-icon-size-md));
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
    color: var(--color-primary);
}



nav a.active{
    background: var(--color-bg);
    color: var(--color-primary);
    font-size: var(--nav-icon-size-md);
}

.nav__icon {
    font-size: var(--nav-icon-size-md);
}

/* ==================== Media Querius (Medium devices) =====================*/

@media screen and (max-width: 1024px) {
    nav a{
        font-size: var(--nav-icon-size-sm);
        padding: 1.6rem;
        
    }

    nav a.active{
        font-size: var(--nav-icon-size-sm);
        padding: 1.6rem;
    }
    
}

/* ==================== Media Querius (Smaller devices) =====================*/

@media screen and (max-width: 1024px) {
    nav a{
        font-size: var(--nav-icon-size-sm);
        padding: 1.0rem;
        
    }

    nav a.active{
        font-size: var(--nav-icon-size-sm);
        padding: 1rem;
    }
    
}