.about__container {
    display: grid;
}

.talk-cta {
    font-size: 1.3rem;
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
}

.about__me {
    margin-top: 3rem;
    width: 20%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent 50%,
        var(--color-primary),
        transparent
    );
display: grid;
place-items: center;
}


.about__cards{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
}



.about__card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    transition: var(--transition);
    align-self: center;
}

.about__card-image{
    border-radius: 1rem;

}


.about__card:hover{
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    cursor: default;
}



.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color:var(--color-light);
}

.about__me__container{
    display: grid;
    margin-top: 2rem;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
}

.about__me-image{
    width: 50%;
    border-radius: 2rem;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
}

.about__socials {
    display: flex;
    gap: 5rem;
    justify-content: center;
    font-size: 2rem;
}
.about__socials__item{
    transition: color var(--transition);
}

.about__socials a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color var(--transition);
}

.about__socials a:hover {
    color: var(--color-primary-variant);
}


/*==================== Media Queries (medium)====================*/

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap:0;
    }
.about__me{
    width: 20%;
    margin: 2rem auto 4rem;
}
.about__content p {
    margin: 1rem 0 1.5rem;
}

.about__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.col-1{
    display: none;
}

.about__socials {
    display: flex;
    gap: 4rem;
    justify-content: center;
    font-size: 1.5rem;
}

.talk-cta {
    font-size: 1rem;
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
}
}

/*==================== Media Queries (small)====================*/

@media screen and (max-width: 600px) {  
.about__me{
    width: 50%;
    margin: 0 auto 3rem;
}
.about__cards {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-left: calc(12%);
    padding-right: calc(12%);
}

.about__content {
    text-align: center;
}

.about__content p {
    margin: 1.5rem 0;
}
.about__me__container{
    display: grid;
    margin-top: 2rem;
    place-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
}
.about__me-image{

    margin-bottom: 2rem;
}


.about__socials {
    display: flex;
    gap: 4rem;
    justify-content: center;
    font-size: 1.2rem;
}
.talk-cta {
    font-size: 0.80rem;
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
}

}
